import { QueryToSearchFormatter } from '~/utils/classes/query-to-search-formatter.class'
import { isObject } from '~/utils/v'
import axios from '~/services/apiClient'
import { vLog } from '~/utils/vLog'
import { checkChange, getFilterTypeKeys } from '~/utils/constants'

class VFilter {
  http = axios

  _route = null
  _curFilter = null
  _queryString = {}
  _queryObject = {}
  _originFilters = {}
  _staticFilters = {}
  _staticParams = {}
  _defaultParams = {}
  _canBeReset = {
    data: [],
    state: false
  }

  searchParamWhitelist = [
    'brand', 'distance', 'duration', 'guests', 'length', 'sortBy',
    'start_date', 'type', 'boat_type_id', 'charter_type_id', 'internal_banner'
    // , 'location'
  ]

  filterSearchParamWhitelist = [
    'bathrooms', 'cabins', 'price_start', 'price_end', 'length', 'year', 'charter_type_id', 'bowThruster',
    'autoPilot', 'licenseRequired', 'professionalHost', 'company', 'model', 'flexibility', 'showNonAvailable', 'marina',
    'equipments', 'subLocations', 'user', 'booking_system_id', 'guestRange', 'extra', 'booking_option_id', 'limitDeal',
    'internal_banner'
  ]

  canBeResetParamsWhitelist = [
    'bathrooms', 'cabins', 'price_start', 'price_end', 'length', 'year', 'charter_type_id', 'bowThruster',
    'autoPilot', 'licenseRequired', 'professionalHost', 'company', 'model', 'flexibility', 'showNonAvailable', 'marina',
    'equipments', 'subLocations', 'user', 'booking_system_id', 'guestRange',
    'brand', 'distance', 'duration', 'guests', 'length', 'page', 'limitDeal',
    'type', 'boat_type_id', 'booking_option_id', 'extra', 'sail_type', 'internal_banner'
  ]

  otherParamsWhitelist = otherParamsWhitelist

  constructor (route = {}) {
    if (route) {
      if (isObject(route)) {
        this.route = route
      }
      if (typeof route === 'string') {
        this.route = route
      }
    }
  }

  get route () {
    return this._route
  }

  set route (route) {
    this._route = route
    if (isObject(route)) {
      if (isObject(route.query)) {
        this.queryObject = route.query
      }
    }
    return this._route
  }

  get originFilters () {
    return this._originFilters
  }

  set originFilters (data) {
    this._originFilters = data
    return this._originFilters
  }

  get queryString () {
    return this._queryString
  }

  set queryString (data) {
    this._queryString = this.convertToString(data)
    this._queryObject = this.convertToObject(data)

    return this._queryString
  }

  get staticFilters () {
    return this._staticFilters
  }

  set staticFilters (data) {
    this._staticFilters = data
    return this._staticFilters
  }

  get defaultParams () {
    return this._defaultParams
  }

  set defaultParams (data) {
    this._defaultParams = data
    return this._defaultParams
  }

  get staticParams () {
    return this._staticParams
  }

  set staticParams (data) {
    this._staticParams = data
    return this._staticParams
  }

  get queryObject () {
    return this._queryObject
  }

  set queryObject (data) {
    this._queryString = this.convertToString(data)
    this._queryObject = this.convertToObject(data)
    this.canBeReset = this._queryObject
    return this._queryObject
  }

  get canBeReset () {
    return this._canBeReset.state
  }

  set canBeReset (data) {
    this._canBeReset.data = []
    for (const [key, value] of Object.entries(data)) {
      if (!this.canBeResetParamsWhitelist.includes(key)) {
        continue
      }
      if (Array.isArray(value) && value.length === 0) {
        continue
      }
      if (checkChange(getFilterTypeKeys(key, { fromKey: 'urlKey' }).key, value)) {
        this._canBeReset.data.push(key)
      }
    }
    this._canBeReset.state = !!this._canBeReset.data.length
  }

  convertToString = (object) => {
    let whitelist = this.searchParamWhitelist

    if (object.filters === true || object.filters === 'true') {
      whitelist = whitelist.concat(this.filterSearchParamWhitelist)
    }

    const otherQuery = {}
    if (this.route?.query) {
      for (const [key, value] of Object.entries(this.route?.query)) {
        if (whitelist.includes(key)) { continue }

        if (this.otherParamsWhitelist.includes(key)) {
          otherQuery[key] = value
          continue
        }
        if (this.otherParamsWhitelist.some(rx => rx.test(key))) {
          otherQuery[key] = value
          continue
        }
      }
    }

    whitelist = whitelist.concat(this.otherParamsWhitelist)

    const result = { ...object, ...otherQuery }
    return Object.keys(result)
      .filter(k => whitelist.includes(k) || whitelist.some(rx => new RegExp(rx).test(k)))
      .filter(k => result[k])
      .filter(k => Array.isArray(result[k]) ? !!result[k].length : true)
      .map(k => `${k}=${result[k] !== null ? encodeURIComponent(result[k]) : ''}`)
      .join('&')
  }

  convertToObject = (url = null, commit = false) => {
    const result = QueryToSearchFormatter.process(url)
    return result
  }

  RESETALL = () => {
    if (process.client && window.vFilter) {
      vLog(this, 'vFilter reset')
      window.vFilter = null
    }
  }
}

export default ({ $axios, store }, inject) => {
  inject('vFilter', (route) => {
    if (typeof window === 'undefined') {
      return new VFilter(route)
    }
    if (process.client && !window.vFilter) {
      window.vFilter = new VFilter(route)
    }
    return window.vFilter
  })
}

export const otherParamsWhitelist = [
  /utm.+/
]
